'use client';

import { useState } from 'react';
import { Breadcrumbs } from 'subframe/components/Breadcrumbs';
import { LearnMoreAccordion } from 'subframe/components/LearnMoreAccordion';
import { StepBase } from 'subframe/components/StepBase';
import useUserAccountState from 'hooks/useUserAccountState';
import Page from 'components/Page';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { useListIngestionTokens } from 'api/frontend';
import { getUnixTime } from 'date-fns';
import TokenSelector from 'components/TokenSelector';
import { AccordionWithUnderline, CodeBlock } from 'subframe/index';
import { TabsWithContent } from 'components/design-system/Tabs';

function SharcInstructionManual() {
  const { account } = useUserAccountState();
  const { data: tokens } = useListIngestionTokens('default', {
    request: {
      headers: {
        Authorization: `Bearer ${account?.token}`,
      },
    },
    swr: {
      revalidateOnFocus: false,
    },
  });
  const [selectedToken, setSelectedToken] = useState<string | undefined>();
  const [installMethod, setInstallMethod] = useState('');

  const activeTokens = tokens?.data.filter((token) =>
    token.revoked
      ? token.revoked == 0 || token.revoked > getUnixTime(new Date())
      : true,
  );
  const [isTokenVisible, setIsTokenVisible] = useState(false);
  const SELECT_ACCESS_TOKEN = 'Select an access token from the above dropdown.';

  return (
    <Page title="SHARC">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>SHARC</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-2">
          <span className="w-full text-body font-body text-default-font">
            Automate your safety checks and enhance reliability with the SHARC
            (Safety Health And Readiness Checks) command-line tool. SHARC
            integrates seamlessly into your existing workflows, helping you
            reduce manual effort whether or not you are using Upgrade Plans.
          </span>

          <div className="flex w-full flex-col items-start gap-2">
            <StepBase
              stepTitle="System Requirements"
              stepBody=""
              stepNumber="1"
              actionButtons={[]}
              defaultOpen={true}
            >
              <StepBase.Container className="h-auto w-full flex-none">
                <StepBase.BulletedLine>
                  OS: Linux or macOS
                </StepBase.BulletedLine>
                <StepBase.BulletedLine>
                  Arch: x86_64 (amd64) or ARM64
                </StepBase.BulletedLine>
              </StepBase.Container>
            </StepBase>
            <StepBase
              stepTitle="Install and Configure the SHARC CLI"
              stepBody="Follow these instructions to download, install, and configure the SHARC CLI for your system."
              stepNumber="2"
              defaultOpen={true}
            >
              <StepBase.Container className="h-auto w-full flex-none">
                {/* Setup Environment Variables */}
                <span className="text-body font-body text-default-font">
                  Setup Environment Variables.
                </span>
                <TabsWithContent
                  initialTabId={installMethod || undefined}
                  onTabChange={(newTabId) => {
                    setInstallMethod(newTabId);
                  }}
                  tabs={[
                    {
                      id: 'default',
                      title: 'Mac OS',
                      content: (
                        <CopyToClipboard
                          language="shell"
                          className="h-auto w-full flex-none select-none cursor-pointer"
                          text={[
                            'export ARCH=arm64',
                            'export OS=darwin',
                            "export SHARC_VERSION='0.1.0'",
                            'export SHARC_PLATFORM=$OS-$ARCH',
                          ].join('\n')}
                          singleLine={false}
                        />
                      ),
                    },
                    {
                      id: 'linux',
                      title: 'Linux',
                      content: (
                        <CopyToClipboard
                          language="shell"
                          className="h-auto w-full flex-none select-none cursor-pointer"
                          text={[
                            'export ARCH=amd64',
                            'export OS=linux',
                            "export SHARC_VERSION='0.1.0'",
                            'export SHARC_PLATFORM=$OS-$ARCH',
                          ].join('\n')}
                          singleLine={false}
                        />
                      ),
                    },
                  ]}
                />

                {/* Download the SHARC Binary */}
                <span className="text-body font-body text-default-font">
                  Download the SHARC Binary.
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={`curl -O https://resources.chkk.dev/sharc/$SHARC_VERSION/sharc-$SHARC_PLATFORM.tar.gz`}
                  singleLine={false}
                />

                {/* Extract the Binary */}
                <span className="text-body font-body text-default-font">
                  Extract the Binary.
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={`tar -xzf sharc-$SHARC_PLATFORM.tar.gz`}
                  singleLine={false}
                />

                {/* Move the Binary to PATH */}
                <span className="text-body font-body text-default-font">
                  Move the Binary to Your PATH (Optional).
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={`sudo mv sharc /usr/local/bin/`}
                  singleLine={false}
                />
                <span className="text-body font-body text-default-font italic">
                  Note: If you choose not to move the binary or lack the
                  necessary permissions, run it using ./sharc for all subsequent
                  commands unless the binary has been added to your PATH.
                </span>

                {/* Verify Installation */}
                <span className="text-body font-body text-default-font">
                  Verify that SHARC is installed successfully
                </span>
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={`sharc --help`}
                  singleLine={false}
                />
                <span className="text-body font-body text-default-font">
                  You should see the following output.
                </span>
                <AccordionWithUnderline headerText="Sample Output:">
                  <CodeBlock className="h-auto w-full flex-none">
                    {`SHARC addresses the challenges of operating dynamic and complex Kubernetes environments by automating comprehensive safety, health, and readiness checks. It provides preflight, in-flight, and postflight checks, incorporating Operational Safety concepts to ensure system health at every stage. Covering over 1,000 add-on versions, SHARC de-risks the upgrade process, mitigating potential breakages and system failures caused by hidden dependencies and unknown incompatibilities.

Usage:
  sharc [command]

Available Commands:
  completion  Generate the autocompletion script for the specified shell
  config      Manage the configuration of SHARC CLI
  configure   Configure the SHARC CLI including the integration with the Chkk API
  help        Help about any command
  run         Run a SHARCflow and report the results to the configured endpoint
  run-one     Run a specific Check from a SHARCPack with the provided parameters and settings

Flags:
  -c, --config string   Specify the config file name to load. It must be in a JSON format
  -h, --help            help for sharc

Use "sharc [command] --help" for more information about a command.`}
                  </CodeBlock>
                </AccordionWithUnderline>

                {/* Configure the SHARC CLI */}
                <span className="text-body font-body text-default-font">
                  <br />
                  After installation, configure SHARC to authenticate with the
                  Chkk Platform using your Chkk token.
                </span>
              </StepBase.Container>
              <span className="text-body font-body text-default-font">
                Select your token. It will be used in the subsequent command to
                set up your CLI. Please note that it is recommended to create a
                new token for the CLI.
              </span>

              <TokenSelector
                label=""
                placeholder="Select a Token"
                helpText=""
                tokens={activeTokens || []}
                value={selectedToken}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
              <span className="text-body font-body text-default-font">
                Run the configuration command in terminal:
              </span>
              <StepBase.Container className="h-auto w-full flex-none">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text="sharc configure"
                  singleLine={false}
                />
              </StepBase.Container>
              <span className="text-body font-body text-default-font">
                You will be prompted to enter your token. Copy and paste the
                token provided below:
              </span>
              {selectedToken ? (
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none sensitive select-none cursor-pointer"
                  text={selectedToken}
                  singleLine={false}
                />
              ) : (
                <CopyToClipboard
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={SELECT_ACCESS_TOKEN}
                  singleLine={false}
                />
              )}

              <StepBase.Container className="h-auto w-full flex-none">
                <span className="text-body font-body text-default-font">
                  Upon entering the token, you should see the following message:
                </span>
                <CodeBlock className="h-auto w-full flex-none">
                  Successfully validated Chkk token
                </CodeBlock>
              </StepBase.Container>
            </StepBase>
            <StepBase
              stepTitle=" Use SHARC CLI"
              stepBody="This guide explains how to use the SHARC CLI to run automated checks for your Kubernetes clusters."
              stepNumber="3"
              defaultOpen={true}
            >
              <StepBase.Container className="h-auto w-full flex-none gap-4">
                {/* Pre-requisites */}
                <div>
                  <span className="text-body font-body text-default-font font-bold">
                    Pre-requisites:
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />- Ensure you have installed and configured the SHARC
                    CLI.
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />- Ensure the Kubernetes cluster you want to run SHARC
                    against is set in your current kubeconfig context. You can
                    check this by running:
                  </span>
                  <CopyToClipboard
                    language="shell"
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text="kubectl config current-context"
                    singleLine={false}
                  />
                  <span className="text-body font-body text-default-font">
                    - Ensure an <strong>Upgrade Plan</strong> is available for
                    the cluster in the Chkk dashboard.
                  </span>
                </div>

                {/* Select the Upgrade Plan */}
                <div>
                  <span className="text-body font-body text-default-font font-bold">
                    Select the Upgrade Plan for the Cluster:
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />- Go to the{' '}
                    <strong>UPGRADE COPILOT → Upgrade Plan</strong> tab and
                    select the upgrade plan for your cluster.
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />- Under the <strong>Preflight Checks</strong> stage,
                    expand the step for the addon for which you want to run
                    SHARC (e.g., <code>Verify cert-manager</code>).
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />- Copy the SHARC CLI command provided (e.g.,{' '}
                    <code>
                      sharc run --flow
                      chkk://shflow_0d836277-15c3-4517-a9ab-87e20d5afe62
                    </code>
                    ).
                  </span>
                </div>

                {/* Run the SHARCflow */}
                <div className="h-auto w-full flex-none">
                  <span className="text-body font-body text-default-font font-bold">
                    Run the SHARCflow:
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />
                    Paste the provided `sharc run` command into your terminal
                    and execute it.
                    <br />
                    On running the command, you should see an output like this:
                  </span>
                  <AccordionWithUnderline
                    headerText="Sample Output:"
                    className="mb-4"
                  >
                    <CodeBlock className="h-auto w-full flex-none">
                      {`03 Feb 25 15:58 GMT | INFO   | definition                     | Downloading SHARCflow definition: chkk://shflow_0d836277-15c3-4517-a9ab-87e20d5afe62
03 Feb 25 15:58 GMT | INFO   | cert-manager/resource-health   | Running Check 1/3
03 Feb 25 15:58 GMT | INFO   | cert-manager/resource-health   | Success: Cert-manager resource is healthy and running.
03 Feb 25 15:58 GMT | INFO   | cert-manager/api-readiness     | Running Check 2/3
03 Feb 25 15:58 GMT | INFO   | cert-manager/api-readiness     | Success: Cert-manager api is ready
03 Feb 25 15:58 GMT | INFO   | cert-manager/end-2-end         | Running Check 3/3
03 Feb 25 15:58 GMT | INFO   | cert-manager/end-2-end         | Success: Cert-manager's functionality verified`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                  <span className="text-body font-body text-default-font">
                    After completion, you will see a summary table like
                    following:
                  </span>
                  <AccordionWithUnderline
                    headerText="Summary Table:"
                    className="mb-4"
                  >
                    <CodeBlock className="h-auto w-full flex-none">
                      {`+---+--------------+------------------------------------------------------------+---------+-----------------------------------------------+
|   | PACK         | CHECK                                                      | OUTCOME | MESSAGE                                       |
+---+--------------+------------------------------------------------------------+---------+-----------------------------------------------+
| 1 | cert-manager | Check Cert-Manager Deployment Health                       | success | Cert-manager resource is healthy and running. |
| 2 | cert-manager | Check Cert-Manager API Readiness and Internal Connectivity | success | Cert-manager api is ready                     |
| 3 | cert-manager | Check Cert-Manager Certificate Issuance Functionality      | success | Cert-manager's functionality verified         |
+---+--------------+------------------------------------------------------------+---------+-----------------------------------------------+`}
                    </CodeBlock>
                  </AccordionWithUnderline>
                </div>

                {/* View Results in Chkk Dashboard */}
                <div>
                  <span className="text-body font-body text-default-font font-bold">
                    View Results in Chkk Dashboard:
                  </span>
                  <span className="text-body font-body text-default-font">
                    <br />
                    After the SHARC run is complete, you can also view the
                    results in the Chkk Dashboard, right below the SHARC run
                    command.
                  </span>
                </div>
              </StepBase.Container>
            </StepBase>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SharcInstructionManual;
