import { useGetUpgradePlan, useListUpgradePlanStages } from 'api/frontend';
import { Breadcrumbs, SkeletonText } from 'subframe/index';
import { ConsoleLoader } from 'components/Loader';
import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useNavigate, useParams } from 'react-router';
import { UPGRADE_STAGES, UPGRADES } from 'src/data/upgrades';
import { UpgradesViewComponent } from '../UpgradesView';
import styles from '../UpgradesView.module.scss';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { toastAutoHideDuration } from 'constants/toasts';
import { useSWRConfig } from 'swr';
import useLocalStorage from 'hooks/useLocalStorage';

export default function UpgradesClusterView() {
  const { upgradeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/plans/clusters',
  );
  const navigate = useNavigate();
  const { mutate: globalMutate } = useSWRConfig();

  // Check if the currently open Upgrade Plan is an example or real
  const exampleData =
    upgradeId && UPGRADES.map((upgr) => upgr.id).includes(upgradeId)
      ? true
      : false;

  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  // update view in localStorage if it doesn't match the currently open Upgrade Plan
  if (exampleData !== showExampleData) {
    setShowExampleData(exampleData);
  }

  const {
    data: upgradePlanData,
    error: upgradePlanError,
    mutate: mutateUpgradePlan,
  } = useGetUpgradePlan(upgradeId || '', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
    swr: {
      enabled: !exampleData,
    },
  });
  const { data: upgradePlanStagesData, error: upgradePlanStagesError } =
    useListUpgradePlanStages(
      upgradeId || '',
      { page_size: 100 },
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
        swr: {
          enabled: !exampleData,
        },
      },
    );

  //redirect to the upgrade clusters page if there is an error in displaying stage data
  if (upgradePlanData && upgradePlanStagesError) {
    navigate(basePath);
    if (
      upgradePlanStagesError?.response?.status === 404 &&
      upgradePlanData?.status === 'cancelled'
    ) {
      enqueueSnackbar('No data available for the canceled Upgrade Plan', {
        variant: 'warning',
        autoHideDuration: toastAutoHideDuration,
      });
    } else if (
      upgradePlanStagesError?.response?.status === 400 &&
      upgradePlanData?.status === 'pending'
    ) {
      enqueueSnackbar(
        'An Upgrade Plan is being generated for you. You will receive a notification when the Plan is ready',
        {
          variant: 'warning',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } else {
      enqueueSnackbar('No data available for the Upgrade Plan', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
  }

  const upgradePlan = exampleData
    ? UPGRADES.find((u) => u.id === upgradeId)
    : upgradePlanData;
  const upgradePlanStages = exampleData
    ? UPGRADE_STAGES.get(upgradeId || '')
    : upgradePlanStagesData?.data;

  const clusterResourcesCount = (upgradePlan?.resources?.clusters || []).length;
  if (!clusterResourcesCount) {
    const updatedPath = window.location.pathname.replace('clusters', 'addons');
    navigate(updatedPath);
  }

  if (upgradePlan === undefined || upgradePlanStages === undefined) {
    return (
      <Page title={'Upgrade Details'}>
        <div
          className={styles['pageContents']}
          style={{ marginLeft: '32px', width: 'calc(100% - 64px)' }}
        >
          <Breadcrumbs>
            <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item>Upgrade Plans</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <SkeletonText className="w-[200px]" />
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item active={true}>
              {upgradePlan?.name || upgradeId}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <ConsoleLoader />
        </div>
      </Page>
    );
  } else if (
    !exampleData &&
    (upgradePlanError !== undefined || upgradePlanStagesError !== undefined)
  ) {
    return (
      <Page title={'Upgrade Details'}>
        <div
          className={styles['pageContents']}
          style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
        >
          <Breadcrumbs>
            <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item>Upgrade Plans</Breadcrumbs.Item>
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <SkeletonText className="w-[200px]" />
            <Breadcrumbs.Divider name="FeatherChevronRight" />
            <Breadcrumbs.Item active={true}>
              {upgradePlan?.name || upgradeId}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <>Upgrade ID not found</>
        </div>
      </Page>
    );
  } else {
    return (
      <UpgradesViewComponent
        account={account}
        upgradePlan={upgradePlan}
        stages={upgradePlanStages}
        navigate={navigate}
        basePath={basePath}
        breadcrumb={'Clusters'}
        onChange={(mutateStage = true, mutatePlan = false) => {
          mutatePlan && mutateUpgradePlan();
          mutateStage &&
            globalMutate((key) => {
              if (Array.isArray(key)) {
                if (
                  (key[0] as string).startsWith(`/upgrades/${upgradePlan.id}/`)
                ) {
                  return true;
                }
              }
              return false;
            });
        }}
      />
    );
  }
}
