import * as SubframeCore from '@subframe/core';
import { Icon } from '@subframe/core';
import {
  UpgradeContentBlock,
  UpgradeContentBlockTableDataCell,
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStep,
  UpgradeStageStepBadgeVariant,
  UpgradeTemplate,
} from 'api/models';
import Page from 'components/Page';
import { Stack } from 'components/utils/Stack';
import { Account } from 'models/account';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip, Divider } from 'components/design-system';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import { toastAutoHideDuration } from 'constants/toasts';
import { formatDistanceToNow } from 'date-fns';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useDrawer } from 'layouts/dashboard/drawer/context';
import { useSnackbar } from 'notistack';
import { UPGRADE_TEMPLATES, UPGRADES } from 'src/data/upgrades';
import { IconWithBackground } from 'subframe/components/IconWithBackground';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import { Steps } from 'subframe/components/Steps';
import { Table } from 'subframe/components/Table';
import {
  AccordionWithUnderline,
  Alert,
  Avatar,
  Badge,
  Breadcrumbs,
  Button,
  CardBase,
  CheckBase,
  Checkbox,
  CtaCard,
  IconButton,
  LearnMoreAccordion,
  RadioGroup,
  StepBase,
  TextArea,
  TextField,
} from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { convertToLowerCaseWithDashes } from 'utils/filters';
import styles from './UpgradesView.module.scss';
import UpgradesDotMenu from './components/DotMenu';
import { UpgradePlanStepMarkdown } from './components/StepMarkdown';
import { UpgradeStepSidebar } from './components/StepSidebar';
import { UpgradeSidebar } from './components/UpgradeSidebar';
import UpgradesSkipStepDialog from './components/UpgradesSkipStepDialog';
import UpgradesUndoSkipStepDialog from './components/UpgradesUndoSkipStepDialog';
import {
  getTooltipForUpgradeCompletedBy,
  getTooltipForUpgradeRequestedBy,
} from './helpers/UpgradesHelpers';
import { StepActionButton } from './helpers/UpgradesViewHelpers';
import { getSkipReasonHuman } from './helpers/skip_reasons';
import { useTemplateIdToNameMap } from './helpers/useTemplateIdToNameMap';
import MarkUpgradePlanAsCompletedDialog from './components/MarkUpgradePlanAsCompletedDialog';
import { Diff } from '../../subframe/components/Diff';
import { EVA_AVATAR_IMAGE } from 'constants/images';
import SHARCFlowCheckResults from './components/SHARCFlowCheckResults';
import DoNotMarkForUpgradeDialog from './components/DoNotMarkForUpgradeDialog';
import { useStatsigClient } from '@statsig/react-bindings';

function getStageVariant(
  stage: UpgradeStage,
  tabIdx: number,
  stageIdx: number,
):
  | 'default'
  | 'success-inactive'
  | 'error-inactive'
  | 'in-progress-inactive'
  | 'blocked-inactive'
  | 'in-progress-active'
  | 'success-active'
  | 'error-active'
  | 'blocked-active' {
  if (tabIdx === stageIdx) {
    if (stage.status === 'completed') {
      return 'success-active';
    }
    if (stage.status === 'blocked') {
      return 'blocked-active';
    }
    return 'in-progress-active';
  } else {
    if (stage.status === 'completed') {
      return 'success-inactive';
    }
    if (stage.status === 'blocked') {
      return 'blocked-inactive';
    }

    return 'in-progress-inactive';
  }
}

function getBadgeVariant(
  variant: UpgradeStageStepBadgeVariant,
): 'brand' | 'neutral' | 'error' | 'warning' | 'success' {
  if (variant === 'success') {
    return 'success';
  } else if (variant === 'warning') {
    return 'warning';
  } else if (variant === 'error') {
    return 'error';
  } else if (variant === 'neutral') {
    return 'neutral';
  } else {
    return 'neutral';
  }
}

function getStageDisabled(stage: UpgradeStage): boolean {
  return stage.status === 'blocked';
}

function getPlanStagesCompleted(stages: UpgradeStage[]) {
  for (let i = 0; i < stages?.length; i++) {
    if (stages[i].status !== 'completed' && stages[i].status !== 'skipped') {
      return false;
    }
  }
  return true;
}

// Expects the name of step to be in the format of "<addon name> <previous version> -> <new version>"
function getAddonNameFromStepName(stepName: string) {
  const pieces = stepName
    .trim()
    .split(' ')
    // In case there are accidental whitespace in between the name and version, eg, RabbitMQ   3.14 -> 4.15
    .filter((p) => p !== '');

  // The last three pieces are always <prev version> -> <new version>, therefore we can skip them
  const addonName = pieces.slice(0, -3).join(' ');

  return addonName;
}

export function UpgradesViewComponent({
  account,
  upgradePlan,
  stages: inputStages,
  navigate,
  basePath,
  breadcrumb,
  onChange,
  isTemplate,
}: {
  account: Account;
  upgradePlan: UpgradePlan;
  stages: UpgradeStage[];
  navigate: NavigateFunction;
  basePath: string;
  breadcrumb: string;
  onChange: (mutateStage?: boolean, mutatePlan?: boolean) => void;
  isTemplate?: boolean;
}) {
  const setRightDrawerContents = useDrawer();
  const stages = inputStages.sort((a, b) => a.stage_number - b.stage_number);

  const [tabIdx, setTabIdx] = useState<number>(0);
  const [openMarkAsCompleted, setOpenMarkAsCompleted] = useState(
    getPlanStagesCompleted(stages) &&
      upgradePlan.status !== 'completed' &&
      upgradePlan.status !== 'cancelled' &&
      !isTemplate,
  );
  const [stepOpen, setStepOpen] = useState<{ [name: string]: boolean }>({});
  const currentStage = stages[tabIdx];
  const showExampleData =
    upgradePlan?.id &&
    (UPGRADES.map((upgr) => upgr.id).includes(upgradePlan?.id) ||
      UPGRADE_TEMPLATES.map((upgr) => upgr.id).includes(upgradePlan?.id))
      ? true
      : false;
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const { client: StatsigClient } = useStatsigClient();
  const [isStageActionPending, setIsStageActionPending] = useState<
    number | null
  >();

  const templateIdToNameMap = useTemplateIdToNameMap({
    token: account.token || '',
    useExampleData: showExampleData,
  });

  // For Addon Upgrade Plan
  const upgradeAddonBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/plans/addons',
  );

  const upgradeTemplatesBasePath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/upgrades/templates/${
      breadcrumb.toLowerCase() === 'clusters' ? 'clusters' : 'addons'
    }`,
  );
  const upgradePlanTemplate = isTemplate
    ? ''
    : templateIdToNameMap.get(upgradePlan.upgrade_template_id || '');

  useEffect(() => {
    if (upgradePlan?.id && currentStage) {
      logEvent('upgrade-plan-viewed', {
        upgrade_plan_id: upgradePlan.id,
        currentStageName: currentStage.name,
      });
    }
  }, [upgradePlan?.id]);
  const showBadge = StatsigClient.checkGate('upgrade_plan_show_badges');
  // once all stages are complete, dialog should popup (without page refresh)
  // using hook instead of adding this to Mark as Done button, since last completed steo can be an automatic check
  useEffect(() => {
    if (getPlanStagesCompleted(stages) && upgradePlan.status !== 'completed') {
      setOpenMarkAsCompleted(true);
    }
  }, [stages]);

  return (
    <Page title={'Upgrade Details'}>
      <div className={styles['pageContents']} style={{ marginLeft: '32px' }}>
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item>
            {isTemplate ? 'Upgrade Templates' : 'Upgrade Plans'}
          </Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item
            data-cy={`${convertToLowerCaseWithDashes(
              `breadcrumb-${breadcrumb?.replace(' ', '').toLowerCase()}`,
            )}`}
            onClick={() => navigate(basePath)}
          >
            {breadcrumb}
          </Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item data-cy="upgrade-plan-name" active={true}>
            {upgradePlan.name}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['stack-838c97b7']}>
          <div className={styles['stack']}>
            <div className={styles['stack-400934b1']}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <span className="text-section-header font-section-header text-default-font">
                  {upgradePlan.name}
                </span>
                <IconButton
                  icon="FeatherActivity"
                  onClick={() => {
                    setRightDrawerContents(
                      `Upgrade: ${upgradePlan.name}`,
                      <UpgradeSidebar
                        upgrade={upgradePlan}
                        stages={stages}
                        isTemplate={isTemplate}
                        upgradePlanTemplate={upgradePlanTemplate}
                      />,
                    );
                  }}
                />
              </div>
              <div className={styles['stack-18c91f9b']}>
                <span className={styles['text']}>ID:</span>
                <span className={styles['text']}>{upgradePlan.id}</span>
              </div>
              {upgradePlanTemplate && upgradePlan.upgrade_template_id && (
                <div className={styles['stack-18c91f9b']}>
                  <span className={styles['text']}>Upgrade Template:</span>

                  <RouterLink
                    className="hoverable-link text-body"
                    to={`${upgradeTemplatesBasePath}/${upgradePlan.upgrade_template_id}`}
                  >
                    <AtomicTooltip tooltipContent="Click to view template">
                      <span>{upgradePlanTemplate}</span>
                    </AtomicTooltip>
                  </RouterLink>
                </div>
              )}
              {upgradePlan.requested_by && upgradePlan.created && (
                <div className={styles['stack-18c91f9b']}>
                  <span className={styles['text']}>Requested by:</span>
                  <AtomicTooltip
                    tooltipContent={getTooltipForUpgradeRequestedBy(
                      upgradePlan.requested_by?.name,
                      upgradePlan.created,
                    )}
                    className="ph-no-capture"
                  >
                    <span
                      className={classNames(styles['text'], 'ph-no-capture')}
                    >
                      {upgradePlan.requested_by?.name || ''}
                    </span>
                  </AtomicTooltip>
                </div>
              )}
              {upgradePlan.completed_by && upgradePlan.updated && (
                <div className={styles['stack-18c91f9b']}>
                  <span className={styles['text']}>{`Marked ${
                    isTemplate ? 'Environment Upgraded' : 'Completed'
                  } by:`}</span>
                  <AtomicTooltip
                    tooltipContent={getTooltipForUpgradeCompletedBy(
                      upgradePlan.completed_by?.name,
                      upgradePlan.updated,
                    )}
                    className="ph-no-capture"
                  >
                    <span
                      className={classNames(styles['text'], 'ph-no-capture')}
                    >
                      {upgradePlan.completed_by?.name || ''}
                    </span>
                  </AtomicTooltip>
                </div>
              )}
            </div>
            {tabIdx !== 0 && (
              <Button
                size="large"
                variant="brand-tertiary"
                icon="FeatherArrowLeft"
                onClick={() => {
                  setTabIdx(tabIdx - 1);
                  logEvent('upgrade-plan-previous-stage-clicked', {
                    upgrade_plan_id: upgradePlan.id,
                    navigatedFromStageName: stages[tabIdx].name,
                    currentStageName: stages[tabIdx - 1].name,
                  });
                }}
              >
                {`Previous Stage: ${stages[tabIdx - 1].name}`}
              </Button>
            )}
            {tabIdx !== stages.length - 1 && (
              <Button
                size="large"
                icon="FeatherArrowRight"
                onClick={() => {
                  setTabIdx(tabIdx + 1);
                  logEvent('upgrade-plan-next-stage-clicked', {
                    upgrade_plan_id: upgradePlan.id,
                    navigatedFromStageName: stages[tabIdx].name,
                    currentStageName: stages[tabIdx + 1].name,
                  });
                }}
              >
                {`${tabIdx === stages.length - 2 ? 'Last' : 'Next'} Stage: ${
                  stages[tabIdx + 1].name
                }`}
              </Button>
            )}
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description=""
          />
        )}
        {(upgradePlan as UpgradeTemplate).status ===
          'regenerate-in-progress' && (
          <Alert
            variant="warning"
            title="This upgrade plan is currently being regenerated."
            description=""
          />
        )}

        {upgradePlan.header !== undefined && (
          <InformationalHeader>
            {upgradePlan.header.items.map((item) => (
              <InformationalHeader.Item
                key={item.title}
                title={item.title}
                icon={
                  item.icon && (
                    <Icon
                      style={{ color: 'var(--subtext-color)' }}
                      name={item.icon as SubframeCore.IconName}
                    />
                  )
                }
                bodySlot={
                  // NOTE: this is a hack, until we find a way to get header items style from json
                  item.title === 'Env' ? (
                    <Badge variant="neutral">{item.value}</Badge>
                  ) : (
                    <span className="text-body-bold font-body-bold text-default-font">
                      {item.value}
                    </span>
                  )
                }
              />
            ))}
          </InformationalHeader>
        )}

        <Steps>
          {stages.map((stage, index) => {
            return (
              <Steps.Step
                key={stage.name}
                name={stage.name}
                firstStep={index === 0}
                lastStep={index === stages.length - 1}
                variant={getStageVariant(stage, tabIdx, index)}
                stepNumber={stage.stage_number.toString()}
                onClick={() => {
                  setTabIdx(index);
                  logEvent('upgrade-plan-stage-clicked', {
                    upgrade_plan_id: upgradePlan.id,
                    stageName: stage.name,
                    navigatedFromStage: stages[tabIdx]?.name,
                  });
                }}
              />
            );
          })}
        </Steps>

        <>
          {currentStage.header_content_blocks?.map((block, idx) => (
            <RenderBlock
              key={idx}
              block={block}
              context={{
                account: account,
                upgrade: upgradePlan,
                stage: currentStage,
                stageIdx: tabIdx,
                moveToNextStage: () => {
                  setTabIdx((tabIdx + 1) % stages.length);
                  window.scrollTo(0, 0);
                  logEvent('upgrade-plan-next-stage-clicked', {
                    upgrade_plan_id: upgradePlan.id,
                    navigatedFromStageName: currentStage.name,
                    stageName: stages[(tabIdx + 1) % stages.length].name,
                  });
                },
              }}
              onChange={onChange}
            />
          ))}
          {currentStage.status === 'blocked' && (
            <Alert
              variant="warning"
              title="This stage is currently locked. Complete the prior stages before executing this stage"
              description=""
              className={styles.fullWidth}
            />
          )}

          {currentStage.steps.length < 1 && (
            <Alert
              variant="warning"
              title="This stage is not available yet"
              description="We will notify you when this stage is available for execution"
            />
          )}

          <div className={styles['crossAxisStretch']}>
            {currentStage.steps
              .sort((a, b) => a.step_number - b.step_number)
              .map((step, index) => {
                const key = `${step.step_number}-${tabIdx}`;

                return (
                  <StepBase
                    // NOTE: we want to force a remount whenever stage changes so that accordion state is reset
                    key={key}
                    stepTitle={step.name}
                    stepBody={step.description}
                    stepNumber={step.step_number.toString()}
                    lastStep={index === stages[tabIdx].steps.length - 1}
                    variant={
                      step.status === 'completed'
                        ? 'success'
                        : step.status === 'skipped'
                        ? 'skipped'
                        : 'default'
                    }
                    defaultOpen={stages[tabIdx].status !== 'completed'}
                    open={stepOpen[key] === undefined ? false : stepOpen[key]}
                    onOpenChange={(open) => {
                      setStepOpen((prev) => {
                        const next = { ...prev };
                        next[key] = open;
                        return next;
                      });
                      if (open) {
                        logEvent('upgrade-plan-step-viewed', {
                          upgrade_plan_id: upgradePlan.id,
                          stageName: currentStage.name,
                          stepName: step.name,
                          stepNumber: step.step_number,
                          stepStatus: step.status,
                        });
                      }
                    }}
                    badges={
                      <>
                        {showBadge &&
                        (step.badges || []).length === 0 ? undefined : (
                          <>
                            {(step.badges || [])
                              .map((badge) => ({
                                ...badge,
                                text: badge.text.trim(),
                              }))
                              .filter((badge) => badge.text !== '')
                              .map((badge, idx) => {
                                return (
                                  <Badge
                                    key={idx}
                                    variant={getBadgeVariant(badge.variant)}
                                  >
                                    {badge.text}
                                  </Badge>
                                );
                              })}
                          </>
                        )}
                        {step.requirement_level === 'required' && (
                          <Badge variant="brand">Required</Badge>
                        )}
                        {step.requirement_level === 'optional' && (
                          <Badge variant="neutral">Optional</Badge>
                        )}
                        {step.status === 'marked-for-upgrade' && (
                          <CommentTooltip
                            userName={
                              step.last_status_change_details?.user?.name
                            }
                            picture={
                              step.last_status_change_details?.user?.picture
                            }
                            comment={
                              step?.last_status_change_details?.comment || ''
                            }
                            time={step?.last_status_change_details?.changed}
                            action={"marked this step as 'For Upgrade'"}
                          >
                            <Badge variant="brand">Marked for Upgrade</Badge>
                          </CommentTooltip>
                        )}
                        {step.status === 'not-marked-for-upgrade' && (
                          <CommentTooltip
                            userName={
                              step.last_status_change_details?.user?.name
                            }
                            picture={
                              step.last_status_change_details?.user?.picture
                            }
                            time={step?.last_status_change_details?.changed}
                            comment={
                              step?.last_status_change_details?.comment || ''
                            }
                            action={"marked this step as 'Don't Upgrade'"}
                          >
                            <Badge variant="neutral">
                              Not Marked for Upgrade
                            </Badge>
                          </CommentTooltip>
                        )}
                        {step.status === 'skipped' &&
                          step.last_status_change_details?.reason !==
                            undefined && (
                            <CommentTooltip
                              userName={
                                step.last_status_change_details?.user?.name
                              }
                              picture={
                                step.last_status_change_details?.user?.picture
                              }
                              comment={
                                step?.last_status_change_details?.comment || ''
                              }
                              time={step?.last_status_change_details?.changed}
                            >
                              <Badge variant="neutral">
                                {`Skipped: ${getSkipReasonHuman(
                                  step.last_status_change_details.reason,
                                )}`}
                              </Badge>
                            </CommentTooltip>
                          )}
                        {step.notes?.content !== undefined &&
                          step.notes?.content !== '' && (
                            <AtomicTooltip
                              tooltipContent={
                                <div style={{ display: 'flex', gap: '8px' }}>
                                  <Avatar
                                    size="x-small"
                                    image={step.notes?.user?.picture}
                                    style={{ width: '16px', height: '16px' }}
                                    className="ph-no-capture"
                                  />
                                  <span className="ph-no-capture">
                                    Last updated by {step.notes?.user?.name}{' '}
                                    {formatDistanceToNow(
                                      new Date(step.notes?.updated * 1000),
                                      { addSuffix: true },
                                    )}
                                  </span>
                                </div>
                              }
                            >
                              <IconWithBackground icon="FeatherEdit2" />
                            </AtomicTooltip>
                          )}
                        {step.comments.latest !== undefined &&
                          step.comments.latest?.comment !== '' && (
                            <CommentTooltip
                              userName={step.comments.latest?.user?.name}
                              picture={step.comments.latest?.user?.picture}
                              comment={step?.comments.latest?.comment}
                              time={step?.comments.latest?.time}
                              action={
                                step.comments.latest?.is_for_chkk
                                  ? 'left comment for Chkk'
                                  : ''
                              }
                            >
                              {step.comments.latest?.is_for_chkk && (
                                <Avatar
                                  image={EVA_AVATAR_IMAGE}
                                  size="x-small"
                                />
                              )}
                            </CommentTooltip>
                          )}
                      </>
                    }
                    actionButtons={
                      <div
                        style={{
                          display: 'inherit',
                          gap: 'inherit',
                        }}
                        onClick={(event) => {
                          // need to stop propagation to avoid odd accordion expand/contract actionButtons
                          // (as clicking on sub-elements inside this div is meant to not trigger those)
                          event.stopPropagation();

                          if (showExampleData) {
                            enqueueSnackbar(
                              'This is a demonstration view only at this point - action buttons are disabled in preview mode',
                              {
                                variant: 'warning',
                                autoHideDuration: toastAutoHideDuration,
                              },
                            );
                          }
                        }}
                      >
                        {step.status !== 'skipped' &&
                          step.status !== 'completed' && (
                            <>
                              {step.allowed_action_types?.includes('skip') && (
                                <UpgradesSkipStepDialog
                                  isTemplate={isTemplate}
                                  account={account}
                                  upgrade={upgradePlan}
                                  stageNumber={currentStage.stage_number}
                                  stepNumber={step.step_number}
                                  onSuccess={() => {
                                    setStepOpen((prev) => {
                                      prev[key] = false;
                                      return prev;
                                    });
                                    onChange(true, false);
                                    logEvent('upgrade-plan-step-skipped', {
                                      upgrade_plan_id: upgradePlan.id,
                                      stageName: currentStage.name,
                                      stepName: step.name,
                                      stepNumber: step.step_number,
                                    });
                                  }}
                                />
                              )}
                              {step.status === 'active' &&
                                step.allowed_action_types?.includes(
                                  'do-not-mark-for-upgrade',
                                ) &&
                                isTemplate && (
                                  <DoNotMarkForUpgradeDialog
                                    variant="brand-tertiary"
                                    account={account}
                                    upgrade={upgradePlan}
                                    stageNumber={currentStage.stage_number}
                                    stepNumber={step.step_number}
                                    isLoading={
                                      isStageActionPending === step.step_number
                                    }
                                    setIsLoading={(isLoading) =>
                                      setIsStageActionPending(
                                        isLoading ? step.step_number : null,
                                      )
                                    }
                                    onConfirm={() => {
                                      onChange(true, false);
                                      setStepOpen((prev) => {
                                        prev[key] = false;
                                        return prev;
                                      });
                                    }}
                                  />
                                )}
                              {step.status === 'active' &&
                                step.allowed_action_types?.includes(
                                  'mark-for-upgrade',
                                ) &&
                                isTemplate && (
                                  <StepActionButton
                                    showActionButton={true}
                                    isTemplate={isTemplate}
                                    variant="brand-primary"
                                    size="medium"
                                    icon="FeatherCheckSquare"
                                    account={account}
                                    upgradeId={upgradePlan.id}
                                    stageNumber={currentStage.stage_number}
                                    stepNumber={step.step_number}
                                    isLoading={
                                      isStageActionPending === step.step_number
                                    }
                                    setIsLoading={(isLoading) =>
                                      setIsStageActionPending(
                                        isLoading ? step.step_number : null,
                                      )
                                    }
                                    update={{ status: 'marked-for-upgrade' }}
                                    onSuccess={() => {
                                      onChange(true, false);
                                      setStepOpen((prev) => {
                                        prev[key] = false;
                                        return prev;
                                      });
                                      logEvent(
                                        'upgrade-plan-step-marked-for-upgrade',
                                        {
                                          upgrade_plan_id: upgradePlan.id,
                                          stageName: currentStage.name,
                                          stepName: step.name,
                                          stepNumber: step.step_number,
                                        },
                                      );
                                      enqueueSnackbar(
                                        'Successfully marked for upgrade',
                                        {
                                          variant: 'success',
                                          autoHideDuration:
                                            toastAutoHideDuration,
                                        },
                                      );
                                    }}
                                    upgradeStatus={upgradePlan.status}
                                  >
                                    Mark for Upgrade
                                  </StepActionButton>
                                )}
                              {step.allowed_action_types?.includes(
                                'mark-completed',
                              ) && (
                                <StepActionButton
                                  isTemplate={isTemplate}
                                  variant="brand-secondary"
                                  size="medium"
                                  icon="FeatherCornerDownRight"
                                  account={account}
                                  upgradeId={upgradePlan.id}
                                  stageNumber={currentStage.stage_number}
                                  stepNumber={step.step_number}
                                  isLoading={
                                    isStageActionPending === step.step_number
                                  }
                                  setIsLoading={(isLoading) =>
                                    setIsStageActionPending(
                                      isLoading ? step.step_number : null,
                                    )
                                  }
                                  update={{ status: 'completed' }}
                                  onSuccess={() => {
                                    setStepOpen((prev) => {
                                      prev[key] = false;
                                      return prev;
                                    });
                                    onChange(true, false);
                                    logEvent('upgrade-plan-step-completed', {
                                      upgrade_plan_id: upgradePlan.id,
                                      stageName: currentStage.name,
                                      stepName: step.name,
                                      stepNumber: step.step_number,
                                    });
                                  }}
                                  upgradeStatus={upgradePlan.status}
                                >
                                  Mark as Done
                                </StepActionButton>
                              )}
                            </>
                          )}
                        {step.allowed_action_types?.includes('undo-skip') &&
                          step.status === 'skipped' && (
                            <UpgradesUndoSkipStepDialog
                              isTemplate={isTemplate}
                              account={account}
                              upgrade={upgradePlan}
                              stageNumber={currentStage.stage_number}
                              stepNumber={step.step_number}
                              onSuccess={() => {
                                setStepOpen((prev) => {
                                  prev[key] = false;
                                  return prev;
                                });
                                onChange(true, false);
                                logEvent('upgrade-plan-step-unskipped', {
                                  upgrade_plan_id: upgradePlan.id,
                                  stageName: currentStage.name,
                                  stepName: step.name,
                                  stepNumber: step.step_number,
                                });
                              }}
                            />
                          )}
                        {step.allowed_action_types?.includes(
                          'undo-mark-completed',
                        ) &&
                          step.status === 'completed' && (
                            <StepActionButton
                              isTemplate={isTemplate}
                              className={styles['button-d226928d']}
                              variant="brand-tertiary"
                              icon="FeatherUndo"
                              size="medium"
                              style={{ display: 'flex' }}
                              account={account}
                              upgradeId={upgradePlan.id}
                              stageNumber={currentStage.stage_number}
                              stepNumber={step.step_number}
                              isLoading={
                                isStageActionPending === step.step_number
                              }
                              setIsLoading={(isLoading) =>
                                setIsStageActionPending(
                                  isLoading ? step.step_number : null,
                                )
                              }
                              update={{ status: 'in-progress' }}
                              onSuccess={() => {
                                onChange(true, false);
                                logEvent('upgrade-plan-step-undone', {
                                  upgrade_plan_id: upgradePlan.id,
                                  stageName: currentStage.name,
                                  stepName: step.name,
                                  stepNumber: step.step_number,
                                });
                              }}
                              upgradeStatus={upgradePlan.status}
                            >
                              Revert
                            </StepActionButton>
                          )}
                        {step.allowed_action_types?.includes(
                          'open-upgrade-plan',
                        ) && (
                          <StepActionButton
                            isTemplate={isTemplate}
                            isRedirect={true}
                            className={styles['button-d226928d']}
                            variant="brand-secondary"
                            size="medium"
                            style={{ display: 'flex' }}
                            account={account}
                            icon="FeatherArrowUpRight"
                            upgradeId={
                              step.description?.startsWith('upgr')
                                ? step.description
                                : ''
                            }
                            stageNumber={currentStage.stage_number}
                            stepNumber={step.step_number}
                            isLoading={
                              isStageActionPending === step.step_number
                            }
                            setIsLoading={(isLoading) =>
                              setIsStageActionPending(
                                isLoading ? step.step_number : null,
                              )
                            }
                            update={{}}
                            basePath={upgradeAddonBasePath}
                            onSuccess={onChange}
                            upgradeStatus={upgradePlan.status}
                          >
                            {`Open ${
                              step?.name
                                ? getAddonNameFromStepName(step.name)
                                : 'Addon'
                            } Upgrade Plan`}
                          </StepActionButton>
                        )}
                        {step.allowed_action_types?.includes(
                          'rerun-checks',
                        ) && (
                          <StepActionButton
                            isTemplate={isTemplate}
                            className={styles['button-d226928d']}
                            variant="brand-secondary"
                            size="medium"
                            style={{ display: 'flex' }}
                            account={account}
                            upgradeId={upgradePlan.id}
                            stageNumber={currentStage.stage_number}
                            stepNumber={step.step_number}
                            isLoading={
                              isStageActionPending === step.step_number
                            }
                            setIsLoading={(isLoading) =>
                              setIsStageActionPending(
                                isLoading ? step.step_number : null,
                              )
                            }
                            update={{
                              health_checks: { aggregate_status: 'pending' },
                            }}
                            onSuccess={onChange}
                            upgradeStatus={upgradePlan.status}
                          >
                            Re-run
                          </StepActionButton>
                        )}
                        <UpgradesDotMenu
                          isTemplate={isTemplate}
                          exampleMode={showExampleData}
                          account={account}
                          upgrade={upgradePlan}
                          stageNumber={currentStage.stage_number}
                          step={step}
                          onChanges={async () => {
                            onChange(true, false);
                          }}
                        />
                        <IconButton
                          icon="FeatherActivity"
                          size="medium"
                          onClick={(event) => {
                            event.stopPropagation();
                            setRightDrawerContents(
                              <Breadcrumbs className="h-auto grow shrink-0 basis-0">
                                <Avatar variant="neutral">
                                  {currentStage.stage_number.toString()}
                                </Avatar>
                                <AtomicTooltip
                                  tooltipContent={currentStage.name}
                                >
                                  <Breadcrumbs.Item>
                                    {`Stage: ${currentStage.name}`}
                                  </Breadcrumbs.Item>
                                </AtomicTooltip>
                                <Breadcrumbs.Divider />
                                <Avatar variant="neutral">
                                  {step.step_number.toString()}
                                </Avatar>
                                <AtomicTooltip tooltipContent={step.name}>
                                  <Breadcrumbs.Item active={true}>
                                    {`Step: ${step.name}`}
                                  </Breadcrumbs.Item>
                                </AtomicTooltip>
                              </Breadcrumbs>,
                              <UpgradeStepSidebar
                                isTemplate={isTemplate}
                                upgrade={upgradePlan}
                                stage={currentStage}
                                step={step}
                              />,
                            );
                          }}
                        />
                      </div>
                    }
                  >
                    {(step.content_blocks || []).length < 1 && <></>}
                    {step.content_blocks?.map((block, blockIdx) => {
                      return (
                        <RenderBlock
                          key={blockIdx}
                          block={block}
                          context={{
                            account: account,
                            upgrade: upgradePlan,
                            stage: currentStage,
                            stageIdx: tabIdx,
                            step: step,
                            stepIdx: index,
                            moveToNextStage: () => {
                              setTabIdx((tabIdx + 1) % stages.length);
                              window.scrollTo(0, 0);
                              logEvent(
                                'upgrade-plan-move-to-next-stage-clicked',
                                {
                                  upgrade_plan_id: upgradePlan.id,
                                  navigatedFromStageName: currentStage.name,
                                  stageName:
                                    stages[(tabIdx + 1) % stages.length]?.name,
                                },
                              );
                            },
                          }}
                          onChange={onChange}
                        />
                      );
                    })}

                    {(step.checks || []).length > 0 && (
                      <Stack>
                        <Table
                          header={
                            <Table.HeaderRow>
                              <Table.HeaderCell>Check</Table.HeaderCell>
                              <Table.HeaderCell>
                                Health Check Status
                              </Table.HeaderCell>
                            </Table.HeaderRow>
                          }
                        >
                          {step.checks?.map((check, i) => {
                            return (
                              <Table.Row key={i} clickable={true}>
                                <Table.Cell>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    className="gap-2"
                                  >
                                    <SubframeCore.Text variant="label">
                                      {check.label}
                                    </SubframeCore.Text>
                                    {check.label_info_tooltip && (
                                      <AtomicTooltip
                                        tooltipContent={
                                          check.label_info_tooltip
                                        }
                                      >
                                        <SubframeCore.Icon name="FeatherInfo" />
                                      </AtomicTooltip>
                                    )}
                                  </Stack>
                                </Table.Cell>
                                <Table.Cell>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    className="gap-2"
                                  >
                                    {check.status === 'passed' && (
                                      <SubframeCore.Icon
                                        name="FeatherCheck"
                                        style={{
                                          color: 'var(--success-800)',
                                        }}
                                      />
                                    )}
                                    {check.status === 'failed' && (
                                      <SubframeCore.Icon
                                        name="FeatherX"
                                        style={{ color: 'var(--error-800)' }}
                                      />
                                    )}
                                    {check.status === 'skipped' && (
                                      <SubframeCore.Icon
                                        name="FeatherSkipForward"
                                        style={{
                                          color: 'var(--warning-800)',
                                        }}
                                      />
                                    )}
                                    {check.status === 'pending' && (
                                      <SubframeCore.Icon
                                        name="FeatherCircle"
                                        style={{
                                          color: 'var(--neutral-800)',
                                        }}
                                      />
                                    )}
                                    <SubframeCore.Text variant="label">
                                      {check.status_text}
                                    </SubframeCore.Text>
                                  </Stack>
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table>
                      </Stack>
                    )}
                    {step.owner === 'customer' && (
                      <UpgradePlanStepMarkdown
                        content={step.notes?.content || ''}
                      />
                    )}
                  </StepBase>
                );
              })}
          </div>

          <div className={styles['stack-96df2aa1']}>
            {currentStage.footer_content_blocks?.map((block, idx) => (
              <RenderBlock
                key={idx}
                block={block}
                context={{
                  account: account,
                  upgrade: upgradePlan,
                  stage: currentStage,
                  stageIdx: tabIdx,
                  moveToNextStage: () => {
                    setTabIdx((tabIdx + 1) % stages.length);
                    window.scrollTo(0, 0);
                    logEvent('upgrade-plan-move-to-next-stage-clicked', {
                      upgrade_plan_id: upgradePlan.id,
                      navigatedFromStageName: currentStage.name,
                      stageName: stages[(tabIdx + 1) % stages.length]?.name,
                    });
                  },
                }}
                onChange={onChange}
              />
            ))}
          </div>
        </>
        <MarkUpgradePlanAsCompletedDialog
          open={openMarkAsCompleted}
          onClose={() => setOpenMarkAsCompleted(false)}
          mutatePlan={() => onChange(false, true)}
          upgradeId={upgradePlan.id}
        />
      </div>
    </Page>
  );
}

interface RenderContext {
  account: Account;
  upgrade: UpgradePlan;
  stage: UpgradeStage;
  stageIdx: number;
  step?: UpgradeStageStep;
  stepIdx?: number;
  moveToNextStage: () => void;
}

function renderTableCell(cell: UpgradeContentBlockTableDataCell): JSX.Element {
  switch (cell.style) {
    case 'normal':
    case undefined: {
      const { value } = cell;
      return (
        <Stack direction="row" spacing={1} className="gap-2">
          <SubframeCore.Text variant="label">{value}</SubframeCore.Text>
          {cell.info_text && (
            <AtomicTooltip tooltipContent={cell.info_text}>
              <SubframeCore.Icon name="FeatherInfo" />
            </AtomicTooltip>
          )}
          {cell.icon && (
            <>
              {cell.icon.variant === 'success' && cell.icon.background && (
                <IconWithBackground variant="success" icon="FeatherCheck" />
              )}
              {cell.icon.variant === 'success' && !cell.icon.background && (
                <SubframeCore.Icon
                  name="FeatherCheck"
                  style={{ color: 'var(--success-800)' }}
                />
              )}
              {cell.icon.variant === 'error' && cell.icon.background && (
                <IconWithBackground variant="error" icon="FeatherX" />
              )}
              {cell.icon.variant === 'error' && !cell.icon.background && (
                <SubframeCore.Icon
                  name="FeatherX"
                  style={{ color: 'var(--error-800)' }}
                />
              )}
            </>
          )}
        </Stack>
      );
    }
    default:
      Sentry.captureException(
        new Error(`Undefined table cell style: ${cell.style}`),
      );
      return <></>;
  }
}

function RenderBlock({
  block,
  context,
  onChange,
}: {
  block: UpgradeContentBlock;
  context: RenderContext;
  onChange: () => void;
}): JSX.Element {
  switch (block.type) {
    case 'text': {
      const { style, text } = block;
      switch (style) {
        case 'header':
          return (
            <SubframeCore.Text variant="subheader">{text}</SubframeCore.Text>
          );
        case 'normal':
        case undefined:
          return <SubframeCore.Text variant="body">{text}</SubframeCore.Text>;
        case 'subtext':
          return (
            <SubframeCore.Text variant="body" color="subtext">
              {text}
            </SubframeCore.Text>
          );
        case 'section-header':
          return (
            <SubframeCore.Text variant="section-header">
              {text}
            </SubframeCore.Text>
          );
        case 'markdown':
          return <UpgradePlanStepMarkdown content={text} />;
        default:
          Sentry.captureException(new Error(`Undefined text style: ${style}`));
          return <></>;
      }
    }
    case 'code': {
      const { style, code, language } = block;
      switch (style) {
        case 'normal':
        case undefined:
          return (
            <Diff clipboardText={code}>
              {code.split('\n').map((line, i) => {
                return <Diff.Line key={i} text={line} language={language} />;
              })}
            </Diff>
          );
        case 'diff':
          return (
            <Diff clipboardText={code}>
              {code.split('\n').map((line, i) => {
                if (line.startsWith('+')) {
                  return (
                    <Diff.Line
                      key={i}
                      text={line.replace(/^\+\s?/, '')}
                      variant="added"
                      language={language}
                    />
                  );
                } else if (line.startsWith('-')) {
                  return (
                    <Diff.Line
                      key={i}
                      text={line.replace(/^-\s?/, '')}
                      variant="deleted"
                      language={language}
                    />
                  );
                } else {
                  return <Diff.Line key={i} text={line} language={language} />;
                }
              })}
            </Diff>
          );
        case 'output':
          return (
            <div className={styles['outputCodeSection']}>
              <div className={styles['outputCode']}>
                <span className={styles['outputCodeText']}>{code}</span>
              </div>
            </div>
          );
        default:
          Sentry.captureException(new Error(`Undefined code style: ${style}`));
          return <></>;
      }
    }
    case 'bullet-list': {
      return (
        <StepBase.Container>
          {block.entries.map((entry, index) => (
            <StepBase.BulletedLine key={index}>
              {entry.text}
            </StepBase.BulletedLine>
          ))}
        </StepBase.Container>
      );
    }
    case 'learn-more': {
      const { title, links } = block;
      return (
        <LearnMoreAccordion
          title={title ? title : 'Learn more about these changes'}
          className="w-full"
        >
          {links.map(({ text, url, url_title }, i) => {
            return (
              <StepBase.TextWithLink
                key={i}
                label={text}
                url={url}
                urlTitle={url_title}
              />
            );
          })}
        </LearnMoreAccordion>
      );
    }
    case 'table': {
      return (
        <div className="flex justify-start items-center overflow-x-auto">
          <Table
            className="max-w-max"
            header={
              <Table.HeaderRow>
                {block.column_definitions?.map((col) => (
                  <Table.HeaderCell
                    key={col.title}
                    className="whitespace-nowrap"
                  >
                    {col.title}
                  </Table.HeaderCell>
                ))}
              </Table.HeaderRow>
            }
          >
            {block?.data?.map((row, i) => {
              return (
                <Table.Row key={i} clickable={true}>
                  {row?.map((cell, j) => (
                    <Table.Cell key={j} className="p-3 h-auto">
                      {renderTableCell(cell)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })}
          </Table>
        </div>
      );
    }
    case 'checkbox-group': {
      return (
        <StepBase.Container>
          <SubframeCore.Text variant="body">{block.label}</SubframeCore.Text>
          <Stack>
            {block.options.map((item, i) => {
              return <Checkbox key={i} label={item.text} />;
            })}
          </Stack>
        </StepBase.Container>
      );
    }
    case 'option-group': {
      return (
        <StepBase.Container>
          <SubframeCore.Text variant="body">{block.label}</SubframeCore.Text>
          <RadioGroup defaultValue={block.options[0].text}>
            {block.options.map((item, i) => {
              return (
                <RadioGroup.Option
                  key={i}
                  label={item.text}
                  value={item.text}
                />
              );
            })}
          </RadioGroup>
        </StepBase.Container>
      );
    }
    case 'completionCtaCard': {
      if (context.stage.status === 'completed') {
        return (
          <CtaCard
            className="h-auto w-full flex-none"
            leftSlot={
              <IconWithBackground
                variant="success"
                size="medium"
                icon="FeatherCheck"
              />
            }
          >
            <span className="text-subheader font-subheader text-default-font">
              {block.title}
            </span>
            <span className="text-body font-body text-default-font">
              {block.text}
            </span>
          </CtaCard>
        );
      } else {
        return <></>;
      }
    }
    case 'banner': {
      return (
        <Alert
          className={styles.fullWidth}
          variant="warning"
          title={block.title}
          description={block.description}
        />
      );
    }
    case 'placeholder-banner': {
      // same as banner but with some additional metadata for automation use
      return (
        <Alert
          className={styles.fullWidth}
          variant="warning"
          title={block.title}
          description={block.description}
        />
      );
    }
    case 'spacer': {
      return <></>;
    }
    case 'info-section': {
      return (
        <InformationalHeader>
          {block.items.map((item, index) => {
            const { title, value, icon } = item;
            return (
              <InformationalHeader.Item
                key={index}
                title={title}
                iconName={
                  icon !== undefined ? (icon as SubframeCore.IconName) : null
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {value}
                  </span>
                }
              />
            );
          })}
        </InformationalHeader>
      );
    }
    case 'text-input': {
      return (
        <StepBase.Container>
          <SubframeCore.Text variant="body">{block.label}</SubframeCore.Text>
          {block.style === 'multi-line' && (
            <TextArea label="" className="w-full" error={false}>
              <TextArea.Input
                className="self-stretch"
                disabled={getStageDisabled(context.stage)}
              />
            </TextArea>
          )}
          {block.style === 'single-line' && (
            <TextField>
              <TextField.Input
                className="self-stretch"
                disabled={getStageDisabled(context.stage)}
              />
            </TextField>
          )}
        </StepBase.Container>
      );
    }
    case 'canaries': {
      return (
        <Stack direction="row" spacing={2} className="flex-wrap">
          {block.canaries.map((canary, i) => {
            return (
              <CardBase
                key={i}
                title={canary.title}
                cardLabel={<Badge variant="neutral">{canary.badge}</Badge>}
                subtitle=""
                actions={null}
                metric1Children={
                  <CardBase.Metric
                    color={canary['before-color']}
                    label="Before Upgrade"
                    amount={canary.before}
                  />
                }
                metric2Children={
                  <CardBase.Metric
                    color={canary['after-color']}
                    label="After Upgrade"
                    amount={canary.after}
                  />
                }
              />
            );
          })}
        </Stack>
      );
    }
    case 'check': {
      let checkVariant: React.ComponentProps<typeof CheckBase>['variant'];
      switch (block.status) {
        case 'default':
          checkVariant = 'default';
          break;
        case 'success':
          checkVariant = 'success';
          break;
        case 'error':
          checkVariant = 'error';
          break;
        case 'loading':
          checkVariant = 'loading';
          break;
        default:
          Sentry.captureException(
            new Error(`Unexpected status ${block.status}`),
          );
          return <></>;
      }
      return (
        <CheckBase
          variant={checkVariant}
          title={block.title}
          description={block.description}
          checkNumber={block.number?.toString()}
        />
      );
    }
    case 'conversation': {
      if (context.step === undefined) {
        // this only works in step context right now
        return <></>;
      }
      Sentry.captureException('Conversation block encountered', {
        level: 'log',
      });
      return <></>;
    }
    case 'divider-line': {
      // return divider line component
      return <Divider variant="neutral" />;
    }
    case 'accordion-underline': {
      return (
        <>
          <span className="text-subheader font-subheader text-default-font">
            {block.title}
          </span>
          <AccordionWithUnderline headerText={block.description}>
            {block?.entries &&
              block.entries.map((subBlock, idx) => (
                <RenderBlock
                  key={idx}
                  block={subBlock}
                  context={context}
                  onChange={onChange}
                />
              ))}
          </AccordionWithUnderline>
        </>
      );
    }
    case 'sharcflow': {
      const sharcFlowId = block?.sharcflow_id;
      return (
        <>
          {sharcFlowId ? (
            <SHARCFlowCheckResults SHARCFlowId={sharcFlowId} />
          ) : (
            <></>
          )}
        </>
      );
    }

    default:
      Sentry.captureException(
        new Error(`Unexpected block type encountered: ${block.type}`),
      );
      return <></>;
  }
}
