'use client';

import { Cluster } from 'api/models';
import { Table } from 'subframe/components/Table';
import { SkeletonText } from 'subframe/index';
import { NeedAttentionCountByCategory } from '../models';
import { useStatsigClient } from '@statsig/react-bindings';

interface ClusterCardRisksCountTooltipProps {
  cluster: Cluster;
  needAttentionCount?: NeedAttentionCountByCategory;
}

function ClusterCardRisksCountTooltip({
  cluster,
  needAttentionCount,
}: ClusterCardRisksCountTooltipProps) {
  const isLastScanSummaryAvailable = !!cluster?.last_scan_summary;
  const { client } = useStatsigClient();

  const showSystemRequirements = client.checkGate('show_system_requirements');

  const {
    defectsNeedAttention,
    misconfigurationsNeedAttention,
    systemRequirementsNeedAttention,
    deprecationsNeedAttention,
    unsupportedVersionsNeedAttention,
    versionsIncompatibilitiesNeedAttention,
  } = {
    defectsNeedAttention: needAttentionCount?.defects_count,
    misconfigurationsNeedAttention: isLastScanSummaryAvailable
      ? cluster?.last_scan_summary?.need_attention_summary
          ?.misconfigurations_count
      : needAttentionCount?.misconfigurations_count,
    systemRequirementsNeedAttention: isLastScanSummaryAvailable
      ? cluster?.last_scan_summary?.need_attention_summary
          ?.system_requirements_count
      : needAttentionCount?.system_requirements_count,

    deprecationsNeedAttention: isLastScanSummaryAvailable
      ? cluster?.last_scan_summary?.need_attention_summary?.deprecations_count
      : needAttentionCount?.deprecations_count,

    unsupportedVersionsNeedAttention: isLastScanSummaryAvailable
      ? cluster?.last_scan_summary?.need_attention_summary
          ?.unsupported_versions_count
      : needAttentionCount?.unsupported_versions_count,

    versionsIncompatibilitiesNeedAttention: isLastScanSummaryAvailable
      ? cluster?.last_scan_summary?.need_attention_summary
          ?.version_incompatibilities_count
      : needAttentionCount?.version_incompatibilities_count,
  };

  const totalNeedAttention =
    (versionsIncompatibilitiesNeedAttention ?? 0) +
    (unsupportedVersionsNeedAttention ?? 0) +
    (deprecationsNeedAttention ?? 0) +
    (systemRequirementsNeedAttention ?? 0) +
    (misconfigurationsNeedAttention ?? 0) +
    (defectsNeedAttention ?? 0);

  const renderCount = (count: number | undefined) =>
    count !== undefined ? count : <SkeletonText className={'w-8'} />;

  return (
    <div
      className="flex flex-col items-start gap-1 rounded bg-default-font pt-1 pr-1 pb-1 pl-1 shadow-large"
      data-cy="need-attention-tooltip"
    >
      <Table
        header={
          <Table.HeaderRow>
            <Table.HeaderCell />
            <Table.HeaderCell>Need Attention</Table.HeaderCell>
          </Table.HeaderRow>
        }
      >
        <Table.Row className="h-8 w-auto flex-none">
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Defects
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-defects-count"
            >
              {defectsNeedAttention !== undefined ? (
                defectsNeedAttention
              ) : (
                <SkeletonText style={{ width: '24px' }} />
              )}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="h-7 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Deprecations
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-deprecations-count"
            >
              {deprecationsNeedAttention !== undefined ? (
                deprecationsNeedAttention
              ) : (
                <SkeletonText style={{ width: '24px' }} />
              )}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row className="h-8 w-auto flex-none">
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Misconfigurations
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-misconfigurations-count"
            >
              {misconfigurationsNeedAttention !== undefined ? (
                misconfigurationsNeedAttention
              ) : (
                <SkeletonText style={{ width: '24px' }} />
              )}
            </span>
          </Table.Cell>
        </Table.Row>

        {showSystemRequirements && (
          <Table.Row>
            <Table.Cell className="h-8 grow shrink-0 basis-0">
              <span className="whitespace-nowrap text-body font-body text-black">
                System Requirements
              </span>
            </Table.Cell>
            <Table.Cell className="h-8 grow shrink-0 basis-0">
              <span
                className="whitespace-nowrap text-body font-body text-black"
                data-cy="need-attention-tooltip-system-requirements-count"
              >
                {systemRequirementsNeedAttention !== undefined ? (
                  systemRequirementsNeedAttention
                ) : (
                  <SkeletonText style={{ width: '24px' }} />
                )}
              </span>
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Unsupported Versions
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-unsupported-versions-count"
            >
              {unsupportedVersionsNeedAttention}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Version Incompatibilities
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-version-incompatibilities-count"
            >
              {versionsIncompatibilitiesNeedAttention !== undefined ? (
                versionsIncompatibilitiesNeedAttention
              ) : (
                <SkeletonText style={{ width: '24px' }} />
              )}
            </span>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span className="whitespace-nowrap text-body font-body text-black">
              Total
            </span>
          </Table.Cell>
          <Table.Cell className="h-8 grow shrink-0 basis-0">
            <span
              className="whitespace-nowrap text-body font-body text-black"
              data-cy="need-attention-tooltip-total-count"
            >
              {renderCount(totalNeedAttention)}
            </span>
          </Table.Cell>
        </Table.Row>
      </Table>
    </div>
  );
}

export default ClusterCardRisksCountTooltip;
