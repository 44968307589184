import { Container, Grid, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosRequestConfig } from 'axios';
import { FAVToggleButton } from 'components/AlignmentToggleButton';
import { ARSigCategoryCard } from 'components/ARSigCategoryCard';
import AvailabilityRisksTable from 'components/AvailabilityRisksTable';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { Card } from 'components/utils/Card';
import { Stack } from 'components/utils/Stack';
import useSettings from 'hooks/useSettings';
import SectionHeader from 'layouts/dashboard/SectionHeader';
import { ARViews } from 'models/general';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce, useSearchParam } from 'react-use';
import { IconWithBackground } from 'subframe/components/IconWithBackground';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import ClusterHelper from 'utils/ClusterHelper';
import { clearSensitivity, splitStringAtUppercase } from 'utils/filters';
import { Text } from '@subframe/core';
import styles from './AvailabilityRisks.module.scss';
import { AtomicTooltip } from 'components/design-system';
import { Alert, Button } from 'subframe/index';
import {
  useGetAvailabilityRisksSummary,
  useListAvailabilityRisks,
} from 'api/frontend';
import {
  AvailabilityRisk,
  GetAvailabilityRisksSummaryParams,
  ListAvailabilityRisksParams,
} from 'api/models';
import { formatQueryParams } from 'utils/formatString';
import { SWRConfiguration } from 'swr';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Searchbar } from 'components/design-system/Searchbar';
import { useStatsigClient } from '@statsig/react-bindings';

export type ARCategories =
  | 'UnsupportedVersions'
  | 'Deprecations'
  | 'Misconfigurations'
  | 'SystemRequirements'
  | 'VersionIncompatibilities'
  | 'Defects';

const getCategoryRisksCountConfig = (token: string, view: ARViews) => {
  const params: GetAvailabilityRisksSummaryParams = { count: '', group_by: [] };
  params.group_by.push('category');
  params.count = 'availability_risk';
  params.filter = [
    'need_attention:true',
    'status:detected,acknowledged',
    `example_cluster:${view === 'example'}`,
  ];
  const config: AxiosRequestConfig = {};
  config.headers = { authorization: `Bearer ${token}` };
  config.paramsSerializer = (params) => formatQueryParams(params);
  config.params = params;
  return config;
};

const getCategoryRisksConfig = (
  token: string,
  category: string,
  view: ARViews,
) => {
  const params: ListAvailabilityRisksParams = {};
  params.filter = [
    'need_attention:true',
    `category:${category}`,
    'status:detected,acknowledged',
    `example_cluster:${view === 'example'}`,
  ];
  const config: AxiosRequestConfig = {};
  config.headers = { authorization: `Bearer ${token}` };
  config.params = params;
  config.paramsSerializer = (params) => formatQueryParams(params);
  return config;
};

export default function AvailabilityRisks() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { account } = useUserAccountState();
  const { getClusters } = ClusterHelper(account?.token);
  const { client: StatsigClient } = useStatsigClient();
  const { logEvent } = AnalyticsEventLogger();
  const addClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );
  const {
    data: clusters,
    isLoading,
    isError,
  } = getClusters({
    revalidateOnFocus: false,
    refreshInterval: 0,
  } as SWRConfiguration);
  const [showTable, setShowTable] = useState(true);
  const [tableIcon, setTableIcon] = useState('mdi:layers-remove');
  const currentTabQS = useSearchParam('category') as ARCategories;
  const [selectedCategory, setSelectedCategory] = useState<ARCategories>(
    currentTabQS || 'UnsupportedVersions',
  );
  const [disableToggle, setDisableToggle] = useState(
    clusters && clusters?.length <= 1,
  );
  const currentView = useSearchParam('view') as ARViews;
  const [view, setView] = useState<ARViews>(
    currentView || (clusters?.length > 1 ? 'real' : 'example'),
  );

  const [risks, setRisks] = useState<AvailabilityRisk[]>([]);
  const [risksToShow, setRisksToShow] = useState([]);
  let categoryCounts = {};
  const [searchAvailabilityRisk, setSearchAvailabilityRisk] = useState('');
  const [riskCategory, setRiskCategory] = useState({
    data: [],
    category: '',
  });
  const showSystemRequirements = StatsigClient.checkGate(
    'show_system_requirements',
  );

  const risksCountConfig = getCategoryRisksCountConfig(account?.token, view);
  const { data: categoryRisksCountsData, isValidating: countsLoading } =
    useGetAvailabilityRisksSummary(risksCountConfig.params, {
      request: risksCountConfig,
      swr: { revalidateOnFocus: true },
    });
  const data = categoryRisksCountsData?.data;
  if (data) {
    const counts = {};
    for (let i = 0; i < data?.length || 0; i++) {
      counts[data[i]?.category.toLowerCase()] = data[i]?.count;
    }
    categoryCounts = counts;
  }
  const categoryRisksConfig = getCategoryRisksConfig(
    account?.token,
    selectedCategory,
    view,
  );
  const { data: categoryRisksData } = useListAvailabilityRisks(
    categoryRisksConfig.params,
    {
      request: categoryRisksConfig,
      swr: { revalidateOnFocus: false },
    },
  );

  const [isTableLoading, setIsTableLoading] = useState(true);

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchAvailabilityRisk(newValue);
  };

  const handleView = (newView: ARViews) => {
    setView(newView);
    const url = new URL(`${window.location}`);
    url.searchParams.set('view', newView);
    window.history.pushState(null, '', url.toString());
    if (newView === 'example') logEvent('fav-example-view');
    else logEvent('fav-real-view');
  };

  const getRisksByCategory = async (category: string) => {
    setIsTableLoading(true);
    const data = categoryRisksData;
    if (categoryRisksData) {
      if (selectedCategory === category) {
        setRisks(categoryRisksData?.data);
        setRiskCategory({ data: data?.data || [], category: category });
        setSearchAvailabilityRisk('');
      }
    }
  };

  useEffect(() => {
    getRisksByCategory(selectedCategory);
  }, [selectedCategory, account?.token, categoryRisksData, clusters]);

  useEffect(() => {
    if (clusters) {
      setDisableToggle(clusters.length <= 1);
      setView(
        clusters.length > 1 ? currentView || 'real' : currentView || 'example',
      );
      if (clusters.length <= 1) {
        const url = new URL(`${window.location}`);
        url.searchParams.set('view', 'example');
        window.history.pushState(null, '', url.toString());
        setView('example');
      }
    }
  }, [clusters, account?.token]);

  useDebounce(
    () => {
      if (searchAvailabilityRisk) {
        logEvent('search-availability-risk', { searchAvailabilityRisk });
      }
    },
    1000,
    [searchAvailabilityRisk],
  );
  useEffect(() => {
    setIsTableLoading(true);
    if (searchAvailabilityRisk !== '' && riskCategory.data) {
      const found = riskCategory.data.filter((item) =>
        item?.title
          ?.toLowerCase()
          .includes(searchAvailabilityRisk.toLowerCase()),
      );
      if (found) {
        setRisksToShow(found);
      }
    } else {
      if (riskCategory.category === selectedCategory) {
        setRisksToShow(riskCategory.data);
      }
    }
    setIsTableLoading(false);
  }, [searchAvailabilityRisk, risks, riskCategory]);

  const cardClick = (category: ARCategories, icon: string) => {
    if (category !== selectedCategory) {
      setIsTableLoading(true);

      setShowTable(true);
      setSelectedCategory(category || '');
      setTableIcon(icon);
      const url = new URL(`${window.location}`);
      url.searchParams.set('category', category);
      window.history.pushState(null, '', url.toString());
    }
    logEvent('availability-risks-card-click', { category });
  };

  const getEmptyTableMsg = (category: string) => {
    switch (clearSensitivity(category)) {
      case 'systemrequirements':
        return `Your clusters meet all ${splitStringAtUppercase(
          category,
        )} scanned by Chkk.`;

      default:
        return `Chkk did not find any ${splitStringAtUppercase(
          category,
        )} that Need Attention in your clusters.`;
    }
  };
  const categoryTabs = [
    {
      title: 'Unsupported Versions',
      type: 'unsupported-versions',
      icon: 'mdi:layers-remove',
      category: 'unsupportedVersions',
      hideSeverity: true,
      tooltipText:
        'Number of unique Unsupported Versions that Need Attention across all clusters. Includes all add-ons that have reached end of support and cluster versions reaching end of support in <30 days.',
    },
    {
      title: 'Defects',
      type: 'defects',
      icon: 'fluent-mdl2:defect-solid',
      category: 'defects',
      hideSeverity: false,
      tooltipText:
        'Number of unique Defects that Need Attention across all clusters. Includes Critical & High Risks while excluding Ignored Defects.',
    },

    {
      title: 'Deprecations',
      type: 'deprecations',
      icon: 'carbon:in-progress-error',
      category: 'deprecations',
      hideSeverity: true,
      tooltipText:
        'Number of unique Deprecations that Need Attention across all clusters. Includes APIs and/or software that will be Deprecated when the cluster or an add-on is upgraded.',
    },
    {
      title: 'Version Incompatibilities',
      type: 'version-incompatibilities',
      icon: 'teenyicons:layers-difference-solid',
      category: 'versionIncompatibilities',
      hideSeverity: true,
      tooltipText:
        'Number of unique Version Incompatibilities that Need Attention across all clusters. Includes all Incompatible Versions reaching end of support by a cloud provider or another add-on.',
    },
    {
      title: 'Misconfigurations',
      type: 'misconfigurations',
      icon: 'fluent:error-circle-settings-20-regular',
      category: 'misocnfigurations',
      hideSeverity: true,
      tooltipText:
        'Number of unique Misconfigurations that Need Attention across all clusters. Includes Critical & High Risks while excluding Ignored Misconfigurations.',
    },
  ];

  if (showSystemRequirements) {
    categoryTabs.push({
      title: 'System Requirements',
      type: 'system-requirements',
      icon: 'pajamas:requirements',
      category: 'systemRequirements',
      hideSeverity: true,
      tooltipText:
        'Number of unique unmet System Requirements that Need Attention across all clusters.',
    });
  }

  const getTableLoading = () => {
    return isTableLoading;
  };

  const { RiskPopoverData } = ARSigCategoryCard;

  return (
    <Page title="Fleet">
      <SectionHeader
        styleType="sticky"
        actionButton={
          <Stack direction="row" alignItems="center" spacing={4}>
            <Stack direction="row" spacing={1} style={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.85rem' }}>
                Show example data
              </Typography>
              <FAVToggleButton
                view={view}
                handleView={handleView}
                disableToggle={disableToggle}
              />
            </Stack>
            <AtomicTooltip
              tooltipContent={
                'Onboard a cluster to find about Availability Risks that Need Attention in your K8s infrastructure.'
              }
            >
              <RouterLink to={addClusterPath}>
                <Button
                  id="add-cluster-button"
                  onClick={() => logEvent('add-cluster')}
                  size="medium"
                  icon="FeatherPlus"
                >
                  Add Cluster
                </Button>
              </RouterLink>
            </AtomicTooltip>
          </Stack>
        }
        title="Fleet"
      />

      <Container maxWidth="100%" sx={{ minHeight: '100%', px: { xs: 4 } }}>
        {view === 'example' && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
            style={{ marginTop: '8px' }}
          />
        )}
        <Scrollbar sx={{ height: '100%' }}>
          {/* ARSIGS */}
          <Grid
            id="arsigs-category-cards"
            container
            spacing={5}
            paddingY={4}
            direction={'row'}
            sx={{ minWidth: 720 }}
          >
            {categoryTabs.map((category) => {
              return (
                <Grid item key={category.title}>
                  <ARSigCategoryCard
                    title={category.title}
                    icon={category.icon}
                    tooltip={<RiskPopoverData title={category.tooltipText} />}
                    onClick={() =>
                      cardClick(category.title.replace(' ', ''), category.icon)
                    }
                    isClicked={
                      splitStringAtUppercase(selectedCategory) ===
                      category.title
                    }
                    loading={countsLoading}
                    NACounts={
                      categoryCounts[
                        category.title.replace(' ', '').toLowerCase()
                      ]
                    }
                  />
                </Grid>
              );
            })}
          </Grid>

          {/* ARSIG TABLE */}
          {showTable && (
            <Card spacing={4} padding="large" id="risks-table">
              <Stack
                className={styles.tableHeader}
                direction="row"
                spacing="fill"
                id="arsigs-category-table-header"
                alignItems="center"
              >
                {countsLoading ? (
                  <>
                    <Skeleton width={300} height="100%" />
                    <Skeleton width={250} height="100%" />
                  </>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      id="arsigs-category-table-header"
                      spacing={2}
                    >
                      <Iconify icon={tableIcon} width={20} height={20} />
                      <Text variant="subheader">
                        {categoryCounts[
                          selectedCategory.replace(' ', '').toLowerCase()
                        ] || 0}{' '}
                        Need Attention
                      </Text>
                    </Stack>

                    {categoryCounts[
                      selectedCategory.replace(' ', '').toLowerCase()
                    ] ? (
                      <Searchbar onChange={handleOnChange} />
                    ) : null}
                  </>
                )}
              </Stack>

              <Stack>
                {countsLoading || isLoading ? (
                  <Stack alignItems={'center'} width="fill">
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        minWidth: '100%',
                        height: '31rem',
                        backgroundColor: theme.palette.grey[900],
                        boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
                        borderRadius: '16px',
                      }}
                    />
                  </Stack>
                ) : (
                  <>
                    {categoryCounts[
                      selectedCategory.replace(' ', '').toLowerCase()
                    ] ? (
                      <AvailabilityRisksTable
                        className={styles.table}
                        loading={getTableLoading()}
                        risks={risksToShow}
                        category={splitStringAtUppercase(
                          selectedCategory.toLowerCase(),
                        )}
                        view={view}
                      />
                    ) : (
                      <Stack
                        alignItems={'center'}
                        justifyContent="center"
                        width="fill"
                        height="fill"
                        spacing={2}
                      >
                        <IconWithBackground
                          icon="FeatherCheck"
                          variant="success"
                          size="medium"
                        />
                        <Stack alignItems="center">
                          <Text variant="subheader">
                            No Issues Need Attention{' '}
                          </Text>
                          <Text
                            variant="body"
                            color="subtext"
                            id="arsigs-category-table-empty-message"
                          >
                            {getEmptyTableMsg(selectedCategory)}
                          </Text>
                        </Stack>
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </Card>
          )}
        </Scrollbar>
      </Container>
    </Page>
  );
}
